import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from 'react-google-recaptcha'; // Import the reCAPTCHA component
import ModalWithPinInput from "../Components/ModalWithPinComponent/ModalWithPinInput";
import { login, verifyPin } from "../../services/authService";
import { ToastContainer } from "react-toastify";
import Cookies from 'js-cookie';
import {
  showSuccessToast,
  showErrorToast,
} from "../../utils/toastUtils";
import Seo from "../../components/SeoComponent/Seo";

const Home = () => {
  const [user_id, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [pin, setPin] = useState('');
  const [tempToken, setTempToken] = useState('');
  const [captchaToken, setCaptchaToken] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const isLoggedIn = !!Cookies.get('authToken');
    if (isLoggedIn) {
      navigate('/admin-dashboard');
    }
  }, [navigate]);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleCaptchaChange = () => {
    setCaptchaToken(!captchaToken);
  };
  

  const handleLogin = async () => {
    if (user_id.trim() === "" || password.trim() === "") {
      showErrorToast("Please enter Username and Password.");
      return;
    }

    if (!captchaToken) {
      showErrorToast("Please complete the reCAPTCHA.");
      return;
    }

    setIsLoading(true);
    try {
      const result = await login({ user_id, password, captchaToken }); // Send captchaToken to the server
      console.log(result);
      if (result.success === false && result.data.temp_token) {
        setTempToken(result.data.temp_token); // Store tempToken after login
        showSuccessToast(result.message || "Step 1 successful. Please verify your PIN.");
        setIsModalOpen(true); // Show PIN verification modal
      } else {
        showErrorToast(result.data.message || "Login failed.");
      }
    } catch (error) {
      showErrorToast("Server error occurred.");
    } finally {
      setIsLoading(false);
    }
  };

  const handlePinSubmit = async () => {
    if (pin.length < 6) {
      showErrorToast("Please enter a valid PIN.");
      return;
    }
  
    setIsLoading(true); // Show loading state
  
    try {
      const result = await verifyPin(pin.join(''), tempToken); // Join PIN digits
      if (result.success) {
        navigate('/admin-dashboard'); // Redirect to dashboard
      } else {
        showErrorToast(result.message);
      }
    } catch (error) {
      showErrorToast("Error verifying PIN.");
    } finally {
      setIsLoading(false); // Hide loading state
    }
  };

  return (
    <>
     <Seo />
      <div className="min-h-screen bg-slate-700 flex flex-col md:flex-row">
        {/* Side Image */}
        <div className="hidden md:block md:w-1/4 xl:w-1/4">
          <img
            src="assets/img/side_img.png"
            alt="sideimg"
            className="h-[96%] w-full object-cover"
          />
        </div>

        {/* Center Area */}
        <div className="md:w-[30%] xl:w-[30%] flex flex-col md:mt-24 items-center text-center">
          <img
            src="assets/img/idspay_logo_log2.png"
            alt="logo"
            className="mb-2"
          />
          <h1 className="text-white text-xl font-bold animate-bounce">
            Welcome Back
          </h1>
          <h2 className="text-white text-2xl font-semibold underline animate-pulse mt-2">
            Login to Admin Pannel
          </h2>
        </div>

        {/* Login Section */}
        <div className="md:w-1/3 xl:w-1/3 p-2 flex flex-col md:mt-56">
          <span
            id="dateTime"
            className="text-black text-s px-1 py-1 rounded-sm shadow-lg hover:bg-sky-700 bg-focus:shadow-outline ml-auto mb-4"
          />
          <div className="">
            <label className="text-white font-bold">User Name</label>
            <input
              type="text"
              placeholder="Username"
              className="border-2 border-solid mb-4 border-blue-800 shadow-xl  shadow-blue-800 p-2 w-full rounded-md transition-all duration-200 ease-in-out focus:border-blue-300 focus:outline-none focus:ring"
              id="user_id"
              name="user_id"
              value={user_id}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
             <label className="text-white font-bold">Password</label>
            <input
              type="password"
              placeholder="Password"
              className="border-2 border-solid border-blue-800 shadow-xl  shadow-blue-800 p-2 w-full rounded-md transition-all duration-200 ease-in-out focus:border-blue-300 focus:outline-none focus:ring"
              id="password"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            {alertMessage && (
              <div className="alert text-red-500">{alertMessage}</div>
            )}
           


            <ReCAPTCHA
                sitekey="6LeJ3isqAAAAAH3IsNxcG-Mh8pKqrbtmelFrieiI"
                onChange={handleCaptchaChange}
                className="mt-6 "
              />


            <div className="flex justify-between items-center mt-6">
              <button
                className={`text-white text-lg px-4 py-2 bg-[#130092] rounded-md hover:bg-sky-800 shadow-xl  shadow-white ${
                  isLoading ? "opacity-50 cursor-not-allowed" : ""
                }`}
                id="login"
                name="login"
                onClick={handleLogin}
                disabled={isLoading}
              >
                {isLoading ? "Logging in..." : "Login"}
                <i className="las la-key ml-2"></i>
              </button>
              {/* reCAPTCHA */}
             
            </div>
          </div>
        </div>

        {/* PIN Modal */}
        <ModalWithPinInput
          isModalOpen={isModalOpen}
          toggleModal={toggleModal}
          tempToken={tempToken}
          onPinSubmit={handlePinSubmit}
        />
      </div>

      <ToastContainer />
    </>
  );
};

export default Home;
