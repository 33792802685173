import React from 'react';

const InputWithLabel = ({ label, placeholder, name, id, type, value, onChange, DivclassName, onBlur, error, required }) => {
  return (
    <div className={DivclassName}>
      <label htmlFor={id} className="block tracking-wide text-s font-bold mb-0">
        {label}  {required && <span className="text-red-500">*</span>}
      </label>
      <input
        id={id}
        type={type}
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur} 
        className="appearance-none block w-full text-gray-700  border border-blue-500 rounded py-2 px-2
         leading-tight focus:outline-none focus:bg-white"
      />
      {error && <p className="text-red-500 text-xs italic">{error}</p>}
    </div>
  );
};

export default InputWithLabel;

