import React, { useState, useEffect } from "react";
import DivTag from "../Components/DivComponent/DivTag";
import Button from "../Components/ButtonComponent/Button";
import FormValidation from "../Components/FormValidation/FormValidation";
import InputWithLabel from "../Components/InputComponent/InputWithLabel";
import SelectWithLabelOption from "../Components/SelectComponent/SelectWithLabelOption";
import ReportHeading from "../Components/ReportHeadings/ReportHeadings";
import SearchApiUserFiled from "../Components/SearchApiUserComponent/SearchApiUserFiled";
import { motion } from "framer-motion";
import printTable from "../Components/TablePrintExcelPdfSearch/tablePrint";
import downloadExcel from "../Components/TablePrintExcelPdfSearch/downloadExcel";
import { fetchCustomerData } from "../../services/customerService";
import Seo from "../../components/SeoComponent/Seo";
import { PhysicalDocumentService } from "../../services/apiService";
import { showErrorToast, showSuccessToast } from "../../utils/toastUtils";
import PopMenu from "../../components/Dialog/PopMenu";
import Option from "../Components/OptionComponent/Option";
import { ToastContainer } from "react-toastify";

const CustomerReport = () => {
  const initialState = {
    from_date: "",
    to_date: "",
    status: "",
    user_id: "",
    total_data: "5",
  };

  const validate = () => {
    let errors = {};
    return errors;
  };

  const { values, errors, handleChange, handleBlur } = FormValidation(
    initialState,
    validate
  );
  const [physcialRemarks, setPhyscialRemarks] = useState('');
  const [physicalKycStatus, setPhysicalKycStatus] = useState('');
  const [userId, setUserId] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [customerData, setCustomerData] = useState([]);
  const [isLoading,setIsLoading]=useState(false);
  const [pagination, setPagination] = useState({
    total: 0,
    per_page: 5,
    current_page: 1,
    last_page: 1,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const handleOpenPhysicalKycApprove = (userId) => {
    setUserId(userId)
    setIsOpen(true)
return
  }
  useEffect(() => {
    const fetchData = async () => {
      try {
        const params = {
          from_date: values.from_date,
          to_date: values.to_date,
          status: values.status,
          user_id: values.user_id,
          page: currentPage,
          total_data: itemsPerPage,
        };
        const response = await fetchCustomerData(params);

        if (response.data && Array.isArray(response.data)) {
          setCustomerData(response.data);
          setPagination(response.pagination || {});
        } else {
          console.error("Data format error:", response.data);
          setCustomerData([]);
          setPagination({});
        }
      } catch (error) {
        console.error("Error fetching customer data:", error);
        setCustomerData([]);
        setPagination({});
      }
    };

    fetchData();
  }, [values, currentPage, itemsPerPage, isOpen]);

  const handleSearchClick = () => {
    setCurrentPage(1); // Reset to the first page on new search
    fetchCustomerData({
      from_date: values.from_date,
      to_date: values.to_date,
      status: values.status,
      user_id: values.user_id,
      page: 1,
      total_data: itemsPerPage,
    });
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handlePrint = () => {
    printTable("table");
  };

  const onDownloadExcel = () => {
    const headers = Object.keys(customerData[0] || {});
    downloadExcel(headers, customerData);
  };

  const onDownloadPdf = () => {
    const headers = Object.keys(customerData[0] || {});
  };

  const formatDate = (dateString) => {
    if (!dateString) return "N/A";

    const date = new Date(dateString);
    const options = { day: "numeric", month: "long", year: "numeric" };

    return date.toLocaleDateString("en-US", options);
  };

  const filteredItems = customerData.map((item, index) => ({
    ...item,
    serialNumber: (currentPage - 1) * itemsPerPage + index + 1,
    user_id: item.user_id || "N/A",
    doc: item.doc ? formatDate(item.doc) : "N/A",
    name: item.name || "N/A",
    authorized_signatory: item.company_details
      ? item.company_details.authorized_person_name || "N/A"
      : "N/A",
    mobile_number: item.mobile_number || "N/A",
    company_cin: item.company_details
      ? item.company_details.company_cin || "N/A"
      : "N/A",
    company_address: item.company_details
      ? item.company_details.company_address || "N/A"
      : "N/A",
    signatory_address: item.landmark_address || "N/A",
    physical_kyc_status: item.kyc_status.value || "N/A",
    digital_kyc_status: item.digital_kyc_status.value || "N/A",
    account_status: item.status.value || "N/A",
  }));
  async function handlePhysicalDocument() {
    try {
      setIsLoading(true)
      const response = await PhysicalDocumentService(
        {
          userId: userId,
          status: physicalKycStatus,
          remarks: physcialRemarks
        }
      );

      if (response.status.code === 200) {
        setPhyscialRemarks('');
        setPhysicalKycStatus('')
        //await UplodedDocumentLists();
        showSuccessToast('Document Approved Successfully');
        setIsLoading(false)
        setIsOpen(false);
        return;
      }
      setIsOpen(false);
      showErrorToast(response.message);
      return;
    } catch (error) {
      setPhyscialRemarks('');
      setPhysicalKycStatus('')
      setIsOpen(false);
      showErrorToast('Failed to approved ');
      setIsLoading(false)
      return
    }
  }
  return (
    <motion.div
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 1.2 }}
    >
      <Seo />
      <DivTag id="" className="mt-8 bg-white">
        <ReportHeading name="Customer Report" />
        <PopMenu visible={isOpen}
          onClose={() => setIsOpen(false)}
          className={"h-[60%] top-[10%] w-1/2"}
          title={"Physical Kyc Status"}
        >
          <h1 className='text-center my-1 text-xl font-semibold text-sky-400'>Approved  Kyc Document </h1>

          <p className='text-center my-1 text-xl font-semibold text-sky-400'>Select userId : {userId}</p>
          <SelectWithLabelOption
            DivclassName="w-full  px-3 md:mb-0"
            label="Status"
            id="status"
            name="status"
            value={physicalKycStatus}
            onChange={(e) => setPhysicalKycStatus(e.target.value)}

            required={true}
          >
            <Option value="">Select Status</Option>
            <Option value="approve">Approve</Option>
            <Option value="reject">Reject</Option>

          </SelectWithLabelOption>

          <div className=' my-2 '>
            <h4 className='text-xl text-black font-bold'>Remarks <span className='text-red-500 text-xl font-semibold'>*</span></h4>

            <textarea
              className='border-2 my-2 border-sky-400 shadow-md outline-none p-2 text-xl w-full
                             bg-white text-black'
              name="remarks" rows={4} cols={25} id="" value={physcialRemarks}
              onChange={(e) => setPhyscialRemarks(e.target.value)} placeholder='write your remarks'></textarea>

          </div>
          <div className='flex w-full px-10 ml-10 justify-around items-center'>

            <button className={isLoading?'cursor-not-allowed':'py-2 -ml-20 px-2 w-1/3 rounded bg-blue-500 text-white cursor-pointer hover:scale-105 transition-all' }onClick={handlePhysicalDocument} disabled={isLoading} >Submit </button>
          </div>

        </PopMenu>
        <DivTag className="flex flex-col items-center gap-2  p-1">
          <DivTag className="flex flex-wrap gap-2">
            <SearchApiUserFiled className="px-2" />
            <InputWithLabel
              DivclassName="w-full md:w-1/6 px-2"
              label="From Date"
              type="date"
              name="from_date"
              id="from_date"
              value={values.from_date}
              onChange={handleChange}
              onBlur={handleBlur}
              className={`border rounded-md py-2 px-3 ${errors.from_date ? "border-red-500" : "border-blue-600"
                }`}
              error={errors.from_date}
            />
            <InputWithLabel
              DivclassName="w-full md:w-1/6 px-2"
              label="To Date"
              type="date"
              name="to_date"
              id="to_date"
              value={values.to_date}
              onChange={handleChange}
              onBlur={handleBlur}
              className={`border rounded-md py-2 px-3 ${errors.to_date ? "border-red-500" : "border-blue-600"
                }`}
              error={errors.to_date}
            />
            <SelectWithLabelOption
              DivclassName="w-full md:w-1/4 px-2"
              label="Records Per Page"
              name="total_data"
              id="total_data"
              value={values.total_data}
              onChange={(e) => {
                handleChange(e);
                setItemsPerPage(Number(e.target.value)); // Update itemsPerPage state
              }}
              className="border  rounded-md py-2 px-3 border-blue-600"
            >
              <option value="10">10</option>
              <option value="30">30</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </SelectWithLabelOption>
          </DivTag>

          <DivTag className="flex justify-center mt-1">
            <Button
              type="button"
              onClick={handleSearchClick}
              className="bg-blue-700 text-white rounded-md px-4 py-2 hover:bg-blue-800 transition duration-300"
            >
              Search
            </Button>
          </DivTag>
        </DivTag>

        <DivTag className="overflow-x-auto my-1 p-2">
          <table id="table" className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr className="bg-blue-700 text-center text-white">
                <th
                  scope="col"
                  className="p-2 text-xs  font-bold uppercase tracking-wider text-center border border-black"
                >
                  S.No
                </th>
                <th
                  scope="col"
                  className=" text-xs  font-bold uppercase tracking-wider text-center border border-black"
                >
                  User ID
                </th>
                <th
                  scope="col"
                  className=" text-xs  font-bold uppercase tracking-wider text-center border border-black"
                >
                  Doc
                </th>
                <th
                  scope="col"
                  className=" text-xs  font-bold uppercase tracking-wider text-center border border-black"
                >
                  Name
                </th>
                <th
                  scope="col"
                  className=" text-xs font-bold uppercase tracking-wider text-center border border-black"
                >
                  Authorized Signatory
                </th>
                <th
                  scope="col"
                  className=" text-xs  font-bold uppercase tracking-wider text-center border border-black"
                >
                  Mobile Number
                </th>
                <th
                  scope="col"
                  className=" text-xs font-bold uppercase tracking-wider text-center border border-black"
                >
                  Company CIN
                </th>
                <th
                  scope="col"
                  className=" text-xs font-bold uppercase tracking-wider text-center border border-black"
                >
                  Company Address
                </th>
                <th
                  scope="col"
                  className=" text-xs font-bold uppercase tracking-wider text-center border border-black"
                >
                  Signatory Address
                </th>
                <th
                  scope="col"
                  className=" text-xs font-bold uppercase tracking-wider text-center border border-black"
                >
                  Physical KYC Status
                </th>
                <th
                  scope="col"
                  className="text-xs font-bold uppercase tracking-wider text-center border border-black"
                >
                  Digital KYC Status
                </th>
                <th
                  scope="col"
                  className=" text-xs font-bold uppercase tracking-wider text-center border border-black"
                >
                  Account Status
                </th>
                <th
                  scope="col"
                  className=" text-xs font-bold uppercase tracking-wider text-center border border-black"
                >
                  Approve Physcial Kyc
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {filteredItems.map((item) => (
                <tr key={item.serialNumber} className="">
                  <td className="p-1 whitespace-nowrap text-sm font-medium text-gray-900 border border-black text-center">
                    {item.serialNumber}
                  </td>
                  <td className=" whitespace-nowrap text-sm font-medium text-gray-900 border border-black text-center">
                    {item.user_id}
                  </td>
                  <td className=" whitespace-nowrap text-sm text-gray-500 border border-black text-center">
                    {item.doc}
                  </td>
                  <td className=" whitespace-nowrap text-sm text-gray-500 border border-black text-center">
                    {item.name}
                  </td>
                  <td className=" whitespace-nowrap text-sm text-gray-500 border border-black text-center">
                    {item.authorized_signatory}
                  </td>
                  <td className=" whitespace-nowrap text-sm text-gray-500 border border-black text-center">
                    {item.mobile_number}
                  </td>
                  <td className=" whitespace-nowrap text-sm text-gray-500 border border-black text-center">
                    {item.company_cin}
                  </td>
                  <td className="whitespace-nowrap text-sm text-gray-500 border border-black text-center">
                    {item.company_address}
                  </td>
                  <td className=" whitespace-nowrap text-sm text-gray-500 border border-black text-center">
                    {item.signatory_address}
                  </td>
                  <td className=" whitespace-nowrap text-sm text-gray-500 border border-black text-center">
                    <button className={item.physical_kyc_status === "approved" ? "bg-green-600 text-white rounded-md px-1 py-0" : "bg-red-600 text-white rounded-md px-1 py-0"}>{item.physical_kyc_status}</button>
                  </td>
                  <td className=" whitespace-nowrap text-sm text-gray-500 border border-black text-center">
                    <button className="bg-red-700 text-white rounded-md px-1 py-0">{item.digital_kyc_status}</button>
                  </td>
                  <td className="whitespace-nowrap text-sm text-gray-500 border border-black text-center">
                    <button className="bg-green-700 text-white rounded-md px-1 py-0"> {item.account_status}</button>
                  </td>
                  <td className="whitespace-nowrap text-sm text-gray-500 border border-black text-center">
                    {
                      item.physical_kyc_status === "approved"
                        ? <p className="text-green-500">Physcial Kyc Approved</p>
                        : item.physical_kyc_status === "rejected"
                          ? <p className="text-red-500">Physical Kyc Rejected</p>
                          : item.physical_kyc_status === "pending" ?
                            <button className='py-1 m-1  px-1  rounded bg-sky-900 text-white cursor-pointer hover:scale-105 transition-all' onClick={() => handleOpenPhysicalKycApprove(item?.user_id)} >Approve Physical Kyc </button>
                            : ""
                    }

                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </DivTag>
        <ToastContainer />
        <DivTag className="flex p-4 justify-center mt-4">
          <Button
            type="button"
            className="bg-blue-700 text-white rounded px-4 py-2 mr-2"
            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
          >
            Previous
          </Button>
          <span className="text-lg">
            {currentPage} / {pagination.last_page || 1}
          </span>
          <Button
            type="button"
            className="bg-blue-700 text-white rounded px-4 py-2 ml-2"
            onClick={() =>
              setCurrentPage((prev) =>
                Math.min(prev + 1, pagination.last_page || 1)
              )
            }
            disabled={currentPage === pagination.last_page}
          >
            Next
          </Button>
        </DivTag>
      </DivTag>
    </motion.div>
  );
};

export default CustomerReport;
