import React, { useState, useEffect, useMemo } from 'react';
import { RiErrorWarningLine } from "react-icons/ri";
import DivTag from '../../Components/DivComponent/DivTag';
import Button from '../../Components/ButtonComponent/Button';
import FormValidation from '../../Components/FormValidation/FormValidation';
import FormTag from '../../Components/FormComponent/FormTag';
import HeadingTag from '../../Components/HeadingComponent/HeadingTag';
import SelectWithLabelOption from '../../Components/SelectComponent/SelectWithLabelOption';
import ReportHeading from '../../Components/ReportHeadings/ReportHeadings';
import Option from '../../Components/OptionComponent/Option';
import SearchApiUserFiled from '../../Components/SearchApiUserComponent/SearchApiUserFiled';
import Pagination from '../../Components/Pagination/Pagination';
import { motion } from 'framer-motion';
import ActionButtonPopup from './ActionButtonPopup';
import DetailsButtonPopup from './DetailsButtonPopup';
import ButtonReport from '../../Components/ButtonComponent/ButtonReport';
import printTable from '../../Components/TablePrintExcelPdfSearch/tablePrint';
import downloadExcel from '../../Components/TablePrintExcelPdfSearch/downloadExcel';
import downloadPdf from '../../Components/TablePrintExcelPdfSearch/downloadPdf';
import InputField from '../../Components/InputComponent/InputField';
import { RetriveActiveServiceReportService, RetriveServiceReportService } from '../../../services/apiaddservice';
import Seo from '../../../components/SeoComponent/Seo';
import { FaInbox } from 'react-icons/fa';


const ActiveServiceReport = () => {

    const [reportData, setReportData] = useState([])
    const [selectedReportId, setSelectedReportId] = useState('');
    const [selectedUserId, setSelectedUserId] = useState('');
    //Action Button PopUp Click 
    const [isModalOpenActionPopup, setIsModalActionPop] = useState(false);

    // Function to open the modal
    const openActionPopup = (reportId, userId) => {
        setSelectedReportId(reportId);
        setSelectedUserId(userId);
        setIsModalActionPop(true);
    };

    // Function to close the modal
    const onCloseActionPop = () => {
        setIsModalActionPop(false);
    };



    // Details Button PopUp Click 
    const [isModalOpenDetailsPopup, setIsModalDetailsPop] = useState(false);

    // Function to open the modal
    const openDetailsPopup = () => {
        setIsModalDetailsPop(true);
    };

    // Function to close the modal
    const onCloseDetailsPop = () => {
        setIsModalDetailsPop(false);
    };





    // Printing function starts here 


    // Printing function
    const handlePrint = () => {
        printTable('table');
    };

    // Printing Function Ends

    // Download function starts here

    // Downloading Function
    const onDownloadExcel = () => {
        // const headers = Object.keys(defaultTableData[0]).filter(header => header !== 'Activate');
        // downloadExcel(headers, defaultTableData);
    };


    // Download function ends here


    // Download pdf function starts here
    const onDownloadPdf = () => {
        // Extract headers from the defaultTableData
        // const headers = Object.keys(defaultTableData[0]).filter(header => header !== 'Activate');

        // // Call downloadPdf with both defaultTableData and headers
        // downloadPdf(defaultTableData, headers);
    };
    // Download pdf function ends here

    // Pagination
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(2); // Change this number as per your requirement

    // Calculate total number of pages
    const totalPages = Math.ceil(reportData.length / itemsPerPage);

    // Logic to handle pagination
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    // Get current items
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;




    //Search By Input Field of Table Data
    const [searchQuery, setSearchQuery] = useState('');

    // Function to handle changes in the search input
    const handleSearchInputChange = (event) => {
        setSearchQuery(event.target.value);
    };





    async function retriveService() {
        try {
            const response = await RetriveActiveServiceReportService();

            if (response.status.code === 200) {
                setReportData(response.data)
            }
        } catch (error) {
            return
        }
    }
    useMemo(() => {
        retriveService();
    }, [])
    return (

        <motion.div
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1.2 }}
        >
            <Seo />

            <DivTag id="" className=" border-2 border-solid border-blue-800 rounded-xl mt-12 bg-white">

                <ReportHeading name="Active Service Report" />
                <DivTag className="
                border-t-0 border-b-0 flex justify-center items-center">


                    <FormTag
                        method=""
                        autoComplete="off" >
                        <DivTag className="flex flex-wrap p-3">

                            <SearchApiUserFiled
                                DivclassName="w-full md:w-1/3 px-2 md:mb-0 text-center mb-0 text-white"
                                DivTagClassName="absolute z-60 ml-3 mt-16 w-40 bg-white border rounded shadow-md"
                            />

                            <SelectWithLabelOption
                                DivclassName="w-full md:w-1/3 px-3 md:mb-0"
                                label="Select Service"
                                id="service"
                                name="service"


                                required={true}

                            >
                                <Option value="">Select Service</Option>
                                <Option value="Varified">service1</Option>
                                <Option value="Reject">service2</Option>
                                <Option value="Pending">service3</Option>
                            </SelectWithLabelOption>


                            <SelectWithLabelOption
                                DivclassName="w-full md:w-1/3 px-3 md:mb-0"
                                label="Status"
                                id="status"
                                name="status"

                                required={true}

                            >
                                <Option value="">Select Status</Option>
                                <Option value="">Disabled</Option>
                                <Option value="Varified">Temp Disabled</Option>
                                <Option value="Pending">Active</Option>
                                <Option value="Reject">Reject</Option>

                            </SelectWithLabelOption>


                        </DivTag>

                    </FormTag>

                    <ButtonReport
                        type="submit"
                        id="submit"
                        name="submit"
                        onClick=""
                        text="submit"

                    />


                </DivTag>


                <HeadingTag className="text-2xl text-blue-800 font-bold bg-white text-center" text="Report" />


                {/* Print,pdf,excel,SearchFilter */}
                <DivTag className="flex">
                    <DivTag className="mr-auto p-0.5">
                        {/* Button printing */}
                        <Button
                            type="button"
                            className="bg-gradient-to-r from-green-400 to-blue-800 hover:from-pink-500 hover:to-yellow-500 text-white border border-white rounded-lg text-md p-1.5"
                            onClick={handlePrint}
                            text="Print"
                        />

                        {/* Button downloadexcel */}
                        <Button
                            type="button"
                            className="bg-gradient-to-r from-green-400 to-blue-800 hover:from-pink-500 hover:to-yellow-500 text-white border border-white rounded-lg text-md p-1.5"
                            onClick={onDownloadExcel}
                            text="Excel"
                        />

                        {/* Button  downloadPdf  */}
                        <Button
                            type="button"
                            className="bg-gradient-to-r from-green-400 to-blue-800 hover:from-pink-500 hover:to-yellow-500 text-white border border-white rounded-lg text-md p-1.5"
                            onClick={onDownloadPdf}
                            text="PDF"
                        />
                    </DivTag>
                    {/* Input Search Field */}

                    <InputField
                        type="text"
                        value={searchQuery}
                        onChange={handleSearchInputChange}
                        placeholder="Search.........."
                        className="appearance-none block text-gray-700 border rounded mb-1
                leading-tight focus:outline-none focus:bg-white text-center"
                    />
                </DivTag>

                <DivTag className="flex justify-center p-0.5">
                    <table className="table table-hover border w-full min-w-full">
                        <thead className="text-md">
                            <tr className="bg-blue-800 text-center text-white">
                                <th className="border-2 p-2">S No.</th>
                                <th className="border-2">UserId</th>
                                <th className="border-2">UserName</th>
                                <th className="border-2">Service Name</th>
                                <th className="border-2">Domain</th>
                                <th className="border-2">Request Date</th>
                                <th className="border-2">Activation Date/Status</th>
                                <th className="border-2">Sales officer</th>
                                <th className="border-2">Details</th>
                                <th className="border-2">Action</th>
                            </tr>
                        </thead>
                        <tbody className="overflow-y-scroll text-center">
                            {
                                reportData.length > 0 ?
                                    reportData.map((item, index) => (
                                        <tr key={index} className='text-center'>
                                            <td className="border text-center">{index + 1}</td>
                                            <td className="border text-center">{item?.user.user_id}</td>
                                            <td className="border text-center">{item?.user.name} </td>
                                            <td className="border text-center">{item?.service.service_name}</td>
                                            <td className='border text-center'>{item.domain}</td>
                                            <td className="border text-center">{item.created_at.substring(0, 10)}</td>

                                            <td className="border text-center">{item.created_at.substring(0, 10)} / {item.status.value}</td>
                                            <td className='border text-center'>{item.service_name}</td>

                                            <td className="border text-center">{item.sales_employee_id}
                                                <RiErrorWarningLine className="ml-4 text-blue-700" onClick={openDetailsPopup} size={24} />
                                            </td>
                                            <td className="border text-center">
                                                {item.Action}
                                                <Button
                                                    type=""
                                                    className="text-white bg-red-500 rounded-sm p-1"
                                                    onClick={()=>openActionPopup(item.id,item.user.user_id)}
                                                >
                                                    Action
                                                </Button>

                                            </td>
                                        </tr>
                                    ))
                                    :
                                    <tr className=' w-full'>

                                        <td className='flex  ml-[20%] text-center text-2xl text-neutral-500 items-center  gap-2 '>
                                            <FaInbox />
                                            <p>Data no found</p>
                                        </td>
                                    </tr>
                            }
                        </tbody>
                    </table>
                </DivTag>

                <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={paginate} />
            </DivTag>
            {isModalOpenActionPopup && <ActionButtonPopup onCloseActionPop={onCloseActionPop} selectedReportId={selectedReportId} selectedUserId={selectedUserId} />} {/* Render the modal */}
            {isModalOpenDetailsPopup && <DetailsButtonPopup onCloseDetailsPop={onCloseDetailsPop} />}
        </motion.div>

    );

};

export default ActiveServiceReport;