import React, { useState, useRef } from 'react';
import PersonalData from './PersonalData/PersonalData';
import CompanyData from './CompanyData/CompanyData';
import SOpaymentData from './S.OPaymentData/SOPaymentData';
import FinishData from './FinsishData/FinishData';
import { motion } from 'framer-motion';
import { RegisterUser } from '../../../services/apiService';
import { showErrorToast, showSuccessToast } from '../../../utils/toastUtils';
import { Stepper } from 'primereact/stepper';
import { StepperPanel } from 'primereact/stepperpanel';
import Seo from '../../../components/SeoComponent/Seo';

const ApiAccount = () => {
    const stepperRef = useRef(null);

    const [userid, setuserId] = useState('');
    const [password, setPassword] = useState('');
    const [pin, setPin] = useState('');
    const [formData, setFormData] = useState({
        user: {
            name: '',
            email_address: '',
            mobile_number: '',
            password: '',
            pin: '',
            pin_code: '',
            landmark_address: '',
            post_office: '',
            district: '',
            state: '',
            dob: '',
            aadhar_number: '',
            pan_number: '',
            pan_image: '',
            aadhar_image: '',
            image: ''

        },
        company: {
            company_name: '',
            company_address: '',
            company_cin: '',
            company_gst_no: '',
            company_pan: '',
            authorized_person_name: '',
            company_type_name: '',
            company_cin_photo: '',
            company_gst_photo: '',
            company_pan_photo: ''
        },
        payment: {
            user_id: '',
            payment_type: '',
            utr: '',
            amount: '',
            status_id: '1'
        }
    });

    const handleInputChange = (section, field, value) => {
        setFormData(prevState => ({
            ...prevState,
            [section]: {
                ...prevState[section],
                [field]: value
            }
        }));
    };

    const handleSubmit = async () => {
        // e.preventDefault();
        try {
            // const response = await axios.post('https://javabackend.idspay.in/api/v1/admin/register/customer', formData);
            const registraionForm = new FormData();
            registraionForm.append('company_cin_photo', formData.company.company_cin_photo);
            registraionForm.append('company_gst_photo', formData.company.company_gst_photo);
            registraionForm.append('company_pan_photo', formData.company.company_pan_photo);
            registraionForm.append("pan_image", formData.user.pan_image);
            registraionForm.append("aadhar_image", formData.user.aadhar_image);
            registraionForm.append("image", formData.user.image);
            const response = await RegisterUser({
                formData: formData,
                comapnyGstImage: registraionForm.get('company_gst_photo'),
                companyCinImage: registraionForm.get('company_cin_photo'),
                companyPanImage: registraionForm.get('company_pan_photo'),
                userPanImage: registraionForm.get('pan_image'),
                userAadharImage: registraionForm.get('aadhar_image'),
                userImage: registraionForm.get('image')
            })

            if (response?.success === true) {
                setPassword(response.password);
                setPin(response.pin);
                setuserId(response.user_id)
                showSuccessToast("Successfully added data");
                Next();
                return
            }
            showErrorToast(response?.message)
            console.log('API response:', response.data);
        } catch (error) {
            console.error('API request failed:', error);
        }
    };
    function Next() {
        stepperRef.current.nextCallback()
    }

    function Prev() {
        stepperRef.current.prevCallback()
    }

    return (
        <>
            <Seo />
            <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1.2 }}
            >


                <div className="card flex justify-center md:mt-8 px-4 sm:px-6 lg:px-8 bg-white">
                    <Stepper ref={stepperRef} className="w-full max-w-full md:mt-16 md:mb-64 border-2 p-2 border-blue-500 rounded-md shadow-xl shadow-blue-800" >
                        <StepperPanel header="Company"  >
                            <CompanyData handleInputChange={handleInputChange} Next={Next} />

                        </StepperPanel>

                        <StepperPanel header="Personal">
                            <PersonalData handleInputChange={handleInputChange} Next={Next} Prev={Prev} />

                        </StepperPanel>

                        <StepperPanel header="S.O & Payment">
                            <SOpaymentData handleInputChange={handleInputChange} handleSubmit={handleSubmit} Next={Next} Prev={Prev} />

                        </StepperPanel>

                        <StepperPanel header="Finish">
                            <FinishData pin={pin} password={password} userId={userid} Next={Next} Prev={Prev} />

                        </StepperPanel>
                    </Stepper>
                </div>


            </motion.div>

        </ >
    );
};

export default ApiAccount;