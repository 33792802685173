import { data } from "jquery";
import { getRequest, postRequest } from "../config/AxiosConfig";
import API_URLS from "../config/BackendConfig";
import { getAuthToken, getToken } from "../utils/tokenUtils"

export const RegisterUser = async ({ formData, comapnyGstImage, companyCinImage, companyPanImage, userAadharImage, userPanImage,userImage }) => {
    try {

        const payload = {
            "user": {
                "name": formData.user.name,
                "email_address": formData.user.email_address,
                "mobile_number": formData.user.mobile_number,
                "password": "password123",

                "pin_code": formData.user.pin_code,
                "landmark_address": formData.user.landmark_address,
                "post_office": formData.user.post_office,
                "district": formData.user.district,
                "state": formData.user.state,
                "dob": formData.user.dob,
                "aadhar_number": formData.user.aadhar_number,
                "pan_number": formData.user.pan_number,
                "pan_image": userPanImage,
                "aadhar_image": userAadharImage,
                "image": userImage
            },
            "company": {
                "company_name": formData.company.company_name,
                "company_address": formData.company.company_address,
                "company_cin": formData.company.company_cin,
                "company_gst_no": formData.company.company_gst_no,
                "company_pan": formData.company.company_pan,
                "authorized_person_name": formData.company.authorized_person_name,
                "company_type_name": formData.company.company_type_name,
                "company_cin_photo": companyCinImage,
                "company_gst_photo": comapnyGstImage,
                "company_pan_photo": companyPanImage
            },
            "payment": {
                "user_id": formData.payment.user_id,
                "payment_type": formData.payment.payment_type,
                "utr": formData.payment.utr,
                "amount": formData.payment.amount,
                "status_id": "1"
            }

        }
        console.log(payload)

        const token = getAuthToken();

        const response = await postRequest({
            url: API_URLS.ADMIN + "register/customer",
            data: payload,
            headers: { 'Authorization': `Bearer ${token}`, "Content-Type": "multipart/form-data", }
        });
        return response;
    } catch (error) {
        return error;
    }
}

export const GetUserDataByIdService = async ({ userId }) => {
    try {
        const payload = {
            'user_id': userId
        }
        const token = getAuthToken();
        const response = postRequest({
            url: API_URLS.ADMIN + "customer/byuserid",
            data: payload,
            headers: { 'Authorization': `Bearer ${token}` }
        });
        return response;
    } catch (error) {
        return error;
    }
}
export const GetUploadedByIdService = async () => {
    try {

        const token = getAuthToken();
        const response = postRequest({
            url: API_URLS.ADMIN + "documents/all",

            headers: { 'Authorization': `Bearer ${token}` }
        });
        return response;
    } catch (error) {
        return error;
    }
}

export const GetUploadedDocumentIdService = async ({ userId }) => {
    try {
        const payload = {
            'userid': userId
        }
        const token = getAuthToken();
        const response = postRequest({
            url: API_URLS.ADMIN + "documents/uploaded",
            data: payload,
            headers: { 'Authorization': `Bearer ${token}` }
        });
        return response;
    } catch (error) {
        return error;
    }
}
export const UploadedDocumentService = async ({ userId, docid, file }) => {
    try {
        const payload = {
            'userid': userId,
            'docid': docid,
            'file': file
        }

        const token = getAuthToken();
        const response = postRequest({
            url: API_URLS.ADMIN + "documents/upload",
            data: payload,
            headers: { 'Authorization': `Bearer ${token}`, "Content-Type": "multipart/form-data", }
        });
        return response;
    } catch (error) {
        return error;
    }
}
export const DeleteDocumentService = async ({ userId, docid }) => {
    try {
        const payload = {
            'docid': docid,
            'userid': userId,


        }

        const token = getAuthToken();
        const response = postRequest({
            url: API_URLS.ADMIN + "documents/delete",
            data: payload,
            headers: { 'Authorization': `Bearer ${token}`, "Content-Type": "multipart/form-data", }
        });
        return response;
    } catch (error) {
        return error;
    }
}
export const ApprovedDocumentService = async ({ userId, docid, remarks }) => {
    try {
        const payload = {

            'userid': userId,
            'docid': docid,
            'remarks': remarks

        }

        const token = getAuthToken();
        const response = postRequest({
            url: API_URLS.ADMIN + "documents/approve",
            data: payload,
            headers: { 'Authorization': `Bearer ${token}`, "Content-Type": "multipart/form-data", }
        });
        return response;
    } catch (error) {
        return error;
    }
}
export const RejectDocumentService = async ({ userId, docid, remarks }) => {
    try {
        const payload = {

            'userid': userId,
            'docid': docid,
            'remarks': remarks

        }

        const token = getAuthToken();
        const response = postRequest({
            url: API_URLS.ADMIN + "documents/reject",
            data: payload,
            headers: { 'Authorization': `Bearer ${token}`, "Content-Type": "multipart/form-data", }
        });
        return response;
    } catch (error) {
        return error;
    }
}
export const PhysicalDocumentService = async ({ userId, status, remarks }) => {
    try {
        const payload = {

            'user_id': userId,
            'status_id': status,
            'remarks': remarks

        }
        console.log(payload)

        const token = getAuthToken();
        const response = postRequest({
            url: API_URLS.ADMIN + "user/kyc-status",
            data: payload,
            headers: { 'Authorization': `Bearer ${token}`, "Content-Type": "multipart/form-data", }
        });
        return response;
    } catch (error) {
        return error;
    }
}
export const DocumentPreviewService = async (filename) => {
    try {
        const token = getAuthToken();
        const res = await getRequest({
            url: API_URLS.ADMIN + `admin-kyc-files/${filename}`,
            headers: { 'Authorization': `Bearer ${token}`, "Content-Type": "multipart/form-data", }
        });
        return res;
    } catch (error) {
        return error;
    }
}
export const linkServicePackage = async (payload) => {
    try {
        console.log(payload)
        const token = getAuthToken();
        const res = await postRequest({
            url: API_URLS.ADMIN + `service-packgae/link`,
            headers: {
                'Authorization': `Bearer ${token}`, "Content-Type": "multipart/form-data",

            },
            data: payload
        });
        return res;
    } catch (error) {
        return error;
    }
}
