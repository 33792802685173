import { postRequest } from "../config/AxiosConfig";
import API_URLS from "../config/BackendConfig";
import { getAuthToken } from "../utils/tokenUtils";

export const fetchCustomerData = async (params) => {
  try {
    const token = getAuthToken();
    console.log(params);
    const response = await postRequest({
      url: API_URLS.ADMIN + "customers",
      data: params,
      headers: { Authorization: `Bearer ${token}` },
    });
console.log(response);
    return response;
  } catch (error) {
    console.error("Error fetching customer data:", error);
    return { error: error.message || "An unexpected error occurred." };
  }
};
