import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import DivTag from '../../Components/DivComponent/DivTag';
import ParagraphTag from '../../Components/ParagraphComponent/ParagraphTag';
import SpanTag from '../../Components/SpanComponent/SpanTag';
import { motion } from 'framer-motion';
import { AiOutlineLogout } from "react-icons/ai";
// import { logout } from '../../../Services/authService';
import { removeauthToken, removeToken } from '../../../utils/tokenUtils';
import { FiRefreshCcw } from "react-icons/fi";
import Cookies from 'js-cookie';
import { logoutService } from '../../../services/authService';
import "./Header.css"
const Header = () => {
    const navigate = useNavigate(); // Hook for programmatic navigation
    const [transalateLan, setTransalteLanguage] = useState('')

    const handleLogout = async () => {
        const response = await logoutService();
        if (response.status === "success") {
            removeauthToken();
            navigate('/')
        }

        removeauthToken();
        navigate('/')
    };
    useEffect(() => {
        var addScript = document.createElement('script');
        addScript.setAttribute(
            'src',
            '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit'
        );
        document.body.appendChild(addScript);
        window.googleTranslateElementInit = googleTranslateElementInit;
    }, []);

    const googleTranslateElementInit = () => {
        window.googleTranslateElementInit = () => {
            new window.google.translate.TranslateElement(
                {
                    pageLanguage: 'en',
                    includedLanguages: 'en,es,hi,gu,bn,sv,ta,te,ur,kn',
                    autoDisplay: true

                },
                'google_translate_element'
            );
        };
    };

    return (
        <motion.div
            animate={{ x: [0, 100, 0] }}
        >
            <div className="fixed top-0 left-0 w-full z-20 bg-blue-700 shadow-lg bg-gradient-to-r from-blue-900 via-teal-400 to-purple-300 rounded-md h-[37px]">
                <p className="header text-white relative flex items-center justify-between font-18">
                    <div className="flex justify-center md:ml-[40%] gap-2 ml-11 ">
                        <span className="md:text-xl text-sm">AEPS Bal </span>
                        <span id="aepsBalance" className="md:text-xl text-sm md:mr-1 hover:bg-zinc-700"> : 8,034</span>
                        <span className="md:text-xl text-sm">Bal </span>
                        <span id="vaBalance" className="md:text-xl text-sm hover:bg-zinc-700"> : 1,046</span>
                        <span className=""><FiRefreshCcw size={24} /></span>
                    </div>

                    {/* <div className='flex ' id="google_translate_element">
                        <p>Transalte:</p>
                        <select class="goog-te-combo" onChange={(e) => setTransalteLanguage(e.target.value)} className='bg-transparent outline-none border-none'>
                            <option value="">Select Language</option>

                            <option value="bn" className='text-black'>Bengali</option>
                            <option value="gu" className='text-black'>Gujarati</option>
                            <option value="en" className='text-black'>English</option>
                            <option value="hi" className='text-black'>Hindi</option>
                            <option value="sv" className='text-black'>Swedish</option>
                            <option value="ta" className='text-black'>Tamil</option>
                            <option value="te" className='text-black'>Telugu</option>

                            <option value="ur" className='text-black'>Urdu</option>

                        </select>
                    </div> */}
                    <div className='flex gap-2'>
                        <p>Language:</p>
                        <div id="google_translate_element"  > </div>
                    </div>
                    <button className='mx-1 flex cursor-pointer' onClick={handleLogout}>
                        <AiOutlineLogout
                            className='mx-2 cursor-pointer text-red-700'
                            size={28}
                        />
                        <span className='md:text-xl text-md font-semibold'>Logout</span>
                    </button>
                </p>
            </div>
        </motion.div>
    );
};

export default Header;
