
import API_URLS from '../../config/BackendConfig';
import { getToken } from '../../utils/tokenUtils';
import { getRequest, postRequest } from '../../config/AxiosConfig';

const apiBaseUrl = API_URLS.ADMIN;

const getAuthHeaders = () => {
  const token = getToken();
  console.log('Token:', token); // Log the token to ensure it's retrieved correctly
  return token ? { Authorization: `Bearer ${token}` } : {};
};


// Add Package Data 
export const AddPackageService = async ({ name }) => {
  try {
    const payload = { "name": name };
    const headers = getAuthHeaders();
    console.log('Request headers:', headers); // Log headers to check for Authorization

    const response = await postRequest({
      url: apiBaseUrl + "package/create",
      headers: headers,
      data: payload,
    });

    //   console.log(response);
    return response;
  } catch (error) {
    console.error('Error adding Package data:', error.response?.data || error.message);
    throw error;
  }
};


// Retrive Package Data from API
export const retrivePackageService = async () => {
  try {
    const headers = getAuthHeaders();

    const response = await postRequest({
      url: apiBaseUrl + "package/report",
      headers: headers
    });
    console.log(response);
    return response;
  } catch (error) {
    console.error('Error fetching Package data:', error.response?.data || error.message);
    throw error;
  }
};
export const retrivePackageServiceByTotal = async ({ total }) => {
  try {
    const headers = getAuthHeaders();
    const payload = {
      "total_data": total
    }
    const response = await postRequest({
      url: apiBaseUrl + "package/report",
      headers: headers,
      data: payload
    });

    return response;
  } catch (error) {
    console.error('Error fetching Package data:', error.response?.data || error.message);
    throw error;
  }
};
export const retrivePackageByServiceIdService = async ({ serviceId }) => {
  try {
    const headers = getAuthHeaders();
    const payload = {
      'service_id': serviceId,

    }
    const response = await postRequest({
      url: apiBaseUrl + "service-packgae/fetch-package",
      headers: headers,
      data: payload
    });

    return response;
  } catch (error) {
    console.error('Error fetching Package data:', error.response?.data || error.message);
    throw error;
  }
};

export const editPackageService = async ({ name, package_id }) => {
  try {
    const headers = getAuthHeaders();

    const payload = { name, package_id }; // Ensure package_id is included
    console.log("Sending Payload:", payload); // Log payload to inspect data
    console.log("p0000", package_id)
    const response = await postRequest({
      url: apiBaseUrl + "package/edit",
      headers: headers,
      data: payload,
    });
    console.log("kkkk", package_id);
    console.log('Edit response:', response);
    return response;
  } catch (error) {
    console.error('Error editing package data:', error.response?.data || error.message);
    throw error;
  }
};


// Update Package Service
export const updatePackageService = async ({ name, package_id }) => {
  try {
    const headers = getAuthHeaders();
    const payload = { name, package_id };
    const response = await postRequest({
      url: apiBaseUrl + "package/update",
      headers: headers,
      data: payload,
    });
    console.log('Update response:', response);
    return response;
  } catch (error) {
    console.error('Error updating package data:', error.response?.data || error.message);
    throw error;
  }
};

