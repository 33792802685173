import React, { useEffect, useState } from "react";



import { motion } from 'framer-motion';


import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { RetriveWalletReportService } from "../../../services/walletservice";


const FundManagementReport = () => {
    const [walletReportData, setWalletReportData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [paginationData, setPaginationData] = useState();
    const [searchText, setSearchText] = useState('');
    const [filterData, setFilterData] = useState({
        from_date: "",
        to_date: "",
        status: "",
        user_id: "",
        page: "",
        total_data: '',
    })


    const handleChange = e => {
        const { name, value } = e.target;
        setFilterData(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    const fetChWalletData = async () => {
        try {

            const response = await RetriveWalletReportService(filterData, currentPage);


            if (response.status.code === 200) {
                setWalletReportData(response.data.data);
                setPaginationData(response.data.pagination)
                return

            } else {
                // console.error("Data format error:", response.data);
                setWalletReportData([]);
                return
            }
        } catch (error) {
            console.error("Error fetching customer data:", error);
            setWalletReportData([]);

        }
    };

    function getTotalTransactionAmountByType(txnType) {
        let total = 0;
        walletReportData.map((report) => {
            if (report.factor === txnType) {
                total = total + parseFloat(report.amount)
            }
        })
        return total;
    }

    const handlerFilterSearch = async () => {



        if (searchText === " ") {
            fetChWalletData();
            return;
        }

        const filterData = walletReportData.filter((curr) => curr.user.user_id.includes(searchText));
        setWalletReportData(filterData);
        return;
    }

    useEffect(() => {
        fetChWalletData();
    }, [currentPage])
    useEffect(() => {
        handlerFilterSearch()
    }, [searchText])
    return (
        <>

            < motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1.2 }}
            >

                <div className="bg-white shadow-md rounded-lg mt-10 p-5">
                    <h3 className="text-2xl px-5 font-bold text-blue-950 rounded-md underline text-center">Wallet Transaction Report</h3>
                    <ToastContainer position="top-right" />
                    <div>
                        <div className="flex justify-between px-3 items-center">

                            <div className="flex  gap-3 mt-10">
                                <div className="flex flex-col relative">
                                    <label htmlFor="from_date">From Date</label>
                                    <input type='date' name="from_date" id="from_date" className="py-1 px-1 rounded border-blue-800 cursor-pointer border shadow-md"
                                        value={filterData.from_date}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="flex flex-col relative">
                                    <label htmlFor="to_date">To Date</label>
                                    <input type='date' name="to_date" id="to_date" className="border-blue-800 py-1 px-1 border rounded outline-none cursor-pointer shadow-md"
                                        value={filterData.to_date}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="flex flex-col relative">
                                    <label htmlFor="user_id">User id</label>
                                    <input type='text' name="user_id" id="user_id" className="border-blue-800 py-1 px-1 border rounded outline-none cursor-pointer shadow-md"
                                        placeholder="AP10023"
                                        value={filterData.user_id.toUpperCase()}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="flex flex-col relative">
                                    <label htmlFor="status">Select Status</label>
                                    <select name="status" id="status" className="border-blue-800 py-1 px-1 border rounded outline-none cursor-pointer shadow-md"
                                        value={filterData.status}
                                        onChange={handleChange}
                                    >
                                        <option value="" disabled selected>Select Status</option>
                                        <option value="approved">Approved</option>
                                        <option value="rejected">Rejected</option>
                                        <option value="pending">Pending</option>
                                    </select>
                                </div>
                                <div className="flex flex-col relative">
                                    <label htmlFor="total_data">Select Total Data</label>
                                    <select name="total_data" id="total_data" className="border-blue-800 py-1 px-1 border rounded outline-none cursor-pointer shadow-md"
                                        value={filterData.total_data}
                                        onChange={handleChange}
                                    >
                                        <option value="" disabled selected>Select Total Data</option>
                                        <option value="1">1</option>
                                        <option value="5">5</option>
                                        <option value="10">10</option>
                                        <option value="20">20</option>
                                        <option value="30">30</option>
                                        <option value="50">50</option>
                                        <option value="200">200</option>
                                        <option value="250">250</option>
                                        <option value="500">500</option>
                                    </select>
                                </div>
                                <button type={"button"}
                                    onClick={fetChWalletData}
                                    className={"text-center text-white bg-blue-700 shadow-lg bg-gradient-to-r from-blue-900 via-teal-400 to-purple-900 font-semibold p-0 rounded-md px-2 py-1 text-lg hover:bg-red-300 h-9 mt-5"}>Submit</button>
                            </div>



                        </div>


                        <div className="flex items-center justify-between gap-3 flex-row my-5">
                            <div className="flex gap-3 justify-around items-center">
                                <p className="text-green-500 font-bold text-xl">Credit:{getTotalTransactionAmountByType('Cr')}</p>
                                <p className="text-red-500 font-bold text-xl">Debit:{getTotalTransactionAmountByType('Dr')}</p>
                                <p className="text-blue-500 font-bold text-xl">Total Available:{getTotalTransactionAmountByType("Cr") - getTotalTransactionAmountByType("Dr")} </p>
                            </div>
                            {/* <div className="flex items-center gap-2">
                                    <p className="text-black font-bold">Search by employee name: </p>

                                    <input
                                        type="text"
                                        placeholder="Search...."
                                        className="px-2 py-1 outline-none  rounded-sm border border-gray-400"
                                        name="searchText"
                                        value={searchText}
                                        onChange={(e)=>setSearchText(e.target.value.toUpperCase())}


                                    />
                                    <FaRedo className="text-xl cursor-pointer "

                                    />
                                </div> */}

                            {/* <button type="button"
                                className="flex gap-3 items-center rounded-md hover:scale-105
                                     transition-all duration-200 px-10 py-2 bg-violet-950 text-white "

                            >
                                <FaDownload /> Excel
                            </button> */}
                        </div>

                        <div className="table w-full mt-10 ">
                            <table className="min-w-full  divide-gray-200 overflow-scroll">
                                <thead className="bg-blue-700 shadow-lg bg-gradient-to-r from-blue-900 via-teal-400 to-purple-900 rounded-sm text-white">
                                    <tr>
                                        <th scope="col" className="px-2 text-center py-3  text-xl font-semibold text-white uppercase tracking-wider">SN.</th>
                                        <th
                                            scope="col"
                                            className="px-2 text-center py-3  text-xl font-semibold text-white uppercase tracking-wider">USER ID</th>
                                        <th
                                            scope="col"
                                            className="px-2 text-center py-3  text-xl font-semibold text-white uppercase tracking-wider">NAME</th>
                                        <th
                                            scope="col"
                                            className="px-2 text-center py-3  text-xl font-semibold text-white uppercase tracking-wider">Amount</th>
                                        <th
                                            scope="col"
                                            className="px-2 text-center py-3  text-xl font-semibold text-white uppercase tracking-wider">Type</th>
                                        <th
                                            scope="col"
                                            className="px-2 text-center py-3  text-xl font-semibold text-white uppercase tracking-wider">Available Balance</th>
                                        <th
                                            scope="col"
                                            className="px-2 text-center py-3  text-xl font-semibold text-white uppercase tracking-wider">Remarks</th>
                                        <th
                                            scope="col"
                                            className="px-2 text-center py-3  text-xl font-semibold text-white uppercase tracking-wider">Date</th>
                                        <th
                                            scope="col"
                                            className="px-2 text-center py-3  text-xl font-semibold text-white uppercase tracking-wider">Status</th>
                                        {/* <th
                                            scope="col"
                                            className="px-2 text-center py-3  text-xs font-medium text-white uppercase tracking-wider">Transacted By
                                        </th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        walletReportData.length > 0 ?
                                            walletReportData.map((report, index) => (
                                                <tr key={index}>
                                                    <td className="px-2 text-center py-4 whitespace-nowrap text-sm text-gray-500">{(currentPage - 1) * parseInt(paginationData.per_page) + index + 1}</td>
                                                    <td className="px-2 text-center py-4 whitespace-nowrap text-sm text-gray-500">{report?.user.user_id}</td>
                                                    <td className="px-2 text-center py-4 whitespace-nowrap text-sm text-gray-500">{report?.user.name}</td>
                                                    <td className="px-2 text-center py-4 whitespace-nowrap text-sm text-gray-500">{report?.amount}</td>
                                                    <td className="px-2 text-center py-4 whitespace-nowrap text-sm text-gray-500">
                                                        {
                                                            report.factor === "Dr"
                                                                ?
                                                                <span className="text-red-400 font-bold">
                                                                    {report.factor}</span>
                                                                :
                                                                <span className="text-green-500 font-bold">{report.factor}</span>
                                                        }
                                                    </td>
                                                    <td className="px-2 text-center py-4 whitespace-nowrap text-sm text-gray-500">{report?.balance_after}</td>
                                                    <td className="px-2 text-center py-4 whitespace-nowrap text-sm text-gray-500">{report?.remarks}</td>
                                                    <td className="px-2 text-center py-4 whitespace-nowrap text-sm text-gray-500">{report?.transaction_date}</td>
                                                    <td className="px-2 text-center py-4 whitespace-nowrap text-sm text-gray-500">{report?.status?.value}</td>
                                                    {/* <td className="px-2 text-center py-4 whitespace-nowrap text-sm text-gray-500"></td> */}
                                                </tr>
                                            ))
                                            :
                                            <tr>
                                                <td colSpan="11" className="text-center text-gray-500">No Data Found</td>
                                            </tr>
                                    }





                                </tbody>
                            </table>
                        </div>

                        <div className="w-full flex justify-center gap-10">
                            <button className="py-1 text-xl  px-4 bg-blue-500 text-white border-none cursor-pointer"
                                onClick={() =>
                                    setCurrentPage((prev) => Math.max(prev - 1, 1))
                                }
                                disabled={currentPage === 1}

                            >Prev</button>
                            <p>{paginationData?.current_page}/{paginationData?.last_page}</p>
                            <button className="py-1 text-xl px-4 bg-blue-900 text-white border-none cursor-pointer"
                                onClick={() =>
                                    setCurrentPage((prev) => Math.min(prev + 1, paginationData?.last_page || 1))
                                }
                                disabled={currentPage === paginationData?.last_page}
                            >Next</button>
                        </div>


                    </div>
                </div>

            </motion.div>

        </>
    );
};
export default FundManagementReport;