import React, { useState } from 'react';
import DivTag from '../../Components/DivComponent/DivTag';
import Button from '../../Components/ButtonComponent/Button';
import FormTag from '../../Components/FormComponent/FormTag';
import FormValidation from '../../Components/FormValidation/FormValidation';
import HeadingTag from '../../Components/HeadingComponent/HeadingTag';
import InputWithLabel from '../../Components/InputComponent/InputWithLabel';
import SelectWithLabelOption from '../../Components/SelectComponent/SelectWithLabelOption';
import ReportHeading from '../../Components/ReportHeadings/ReportHeadings';
import PopUpConfig from './PopUpConfig';
import { motion } from 'framer-motion';
import Pagination from '../../Components/Pagination/Pagination';
import printTable from '../../Components/TablePrintExcelPdfSearch/tablePrint';
import downloadExcel from '../../Components/TablePrintExcelPdfSearch/downloadExcel';
import downloadPdf from '../../Components/TablePrintExcelPdfSearch/downloadPdf';
import InputField from '../../Components/InputComponent/InputField';
import 'jspdf-autotable'; // Import AutoTable plugin


const IdfcVanConfig = () => {

  //Config Report page popup
  const [isModalOpenConfig, setIsModalOpenConfig] = useState(false);

  const openConfigModal = () => {
    setIsModalOpenConfig(true);
  };

  const closeModalConfig = () => {
    setIsModalOpenConfig(false);
  };


    const initialState = {
        from_date: '',
        to_date: '',
        van: '',
        status: '',
        BankName: '',
    };

    const validate = (values) => {
        let errors = {};

        // Add validation logic here
        if (!values.from_date) {
            errors.from_date = 'From Date is required';
        }
        if (!values.to_date) {
            errors.to_date = 'To Date is required';
        }
        if (!values.van) {
            errors.van = 'VAN is required';
        }
        if (!values.status || values.status === '') {
            errors.status = 'Status is required';
        }
        if (!values.BankName || values.BankName === '') {
            errors.BankName = 'BankName is required';
        }
        return errors;
    };

    const { values, errors, handleChange, handleBlur } = FormValidation(
        initialState,
        validate
    );

    // Define default table data
    const [defaultTableData, setDefaultTableData] = useState([
        {
            SrNo: 1,
            Van: 'No',
            Status: 'Pending',
            DateOfCreation: '2006/02/05',
            DailyLimit: "86754",
            BankName: 'Axis',
        },
        {
            SrNo: 2,
            Van: 'Yes',
            Status: 'Approved',
            DateOfCreation: '2007/11/12',
            DailyLimit: "34534",
            BankName: 'IDFCBank',
        },
        {
            SrNo: 3,
            Van: 'No',
            Status: 'Pending',
            DateOfCreation: '20012/02/24',
            DailyLimit: "86734",
            BankName: 'GraminBank',
        },
        {
            SrNo: 4,
            Van: 'no',
            Status: 'Pending',
            DateOfCreation: '2020/10/10',
            DailyLimit: "1234",
            BankName: 'UnionBank',
        },
        {
            SrNo: 5,
            Van: 'Yes',
            Status: 'Approved',
            DateOfCreation: '2022/03/25',
            DailyLimit: "9734",
            BankName: 'SBI',
        },

        // Add more default data objects as needed
    ]);


    // State to track if search button is clicked
    const [searchClicked, setSearchClicked] = useState(false);

    // State to track the index of the row being edited
    const [editIndex, setEditIndex] = useState(null);

    const handleInputChange = (e, index, fieldName) => {
        const { value } = e.target;
        const newData = [...defaultTableData];
        newData[index][fieldName] = value;
        setDefaultTableData(newData);
    };

    // Function to handle search button click
    const handleSearchClick = () => {
        // Update the state to indicate that the search button is clicked
        setSearchClicked(true);
    };

    // Function to handle edit click
    const handleEditClick = (index) => {
        setEditIndex(index);
    };

    // Function to handle update click
    const handleUpdateClick = () => {
        // Logic to update the row data
        setEditIndex(null); // Reset editIndex after updating
    };


    // Function to handle delete click that will delete that row
    const handleDeleteClick = (index) => {
        const updatedTableData = [...defaultTableData];
        updatedTableData.splice(index, 1);
        setDefaultTableData(updatedTableData);
    };



   // Pagination
   const [currentPage, setCurrentPage] = useState(1);
   const [itemsPerPage] = useState(4); // Change this number as per your requirement

   // Calculate total number of pages
   const totalPages = Math.ceil(defaultTableData.length / itemsPerPage);

   // Logic to handle pagination
   const paginate = (pageNumber) => setCurrentPage(pageNumber);

   // Printing function starts here 


   // Printing function
   const handlePrint = () => {
       printTable('table');
   };

   // Printing Function Ends


   // Download function starts here

   // Downloading Function
   const onDownloadExcel = () => {
       const headers = Object.keys(defaultTableData[0]).filter(header => header !== 'Activate');
       downloadExcel(headers, defaultTableData);
   };

   // Download function ends here


   // Download pdf function starts here
   const onDownloadPdf = () => {
       // Extract headers from the defaultTableData
       const headers = Object.keys(defaultTableData[0]).filter(header => header !== 'Activate');

       // Call downloadPdf with both defaultTableData and headers
       downloadPdf(defaultTableData, headers);
   };
   // Download pdf function ends here

   // Get current items
   const indexOfLastItem = currentPage * itemsPerPage;
   const indexOfFirstItem = indexOfLastItem - itemsPerPage;
   const currentItems = defaultTableData.slice(indexOfFirstItem, indexOfLastItem);



   //Search By Input Field of Table Data
   const [searchQuery, setSearchQuery] = useState('');

   // Function to handle changes in the search input
   const handleSearchInputChange = (event) => {
       setSearchQuery(event.target.value);
   };

   // Filtered items based on search query
   const filteredItems = currentItems.filter(item =>
       item.Status.toLowerCase().includes(searchQuery.toLowerCase()) ||
       item.DailyLimit.toLowerCase().includes(searchQuery.toLowerCase()) ||
       item.BankName.toLowerCase().includes(searchQuery.toLowerCase()) ||
       item.DateOfCreation.toLowerCase().includes(searchQuery.toLocaleLowerCase())
   );
   //Search Filter End



    return (
        <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1.2 }}
    >
           {/* <DivTag id="" className=" border-2 border-solid border-blue-800 rounded-xl mt-12 bg-white"> */}
           <DivTag id="" className=" border-2 border-solid border-blue-800 rounded-xl md:mt-12 mt-5 bg-white">

               <ReportHeading name="VanConfig Report" />
               <FormTag method="" autoComplete="off">
                    <DivTag className="flex justify-center items-center bg-white">
                    
                        <DivTag className="flex flex-wrap mt-2">

                            <InputWithLabel
                                DivclassName="w-1/2 md:w-1/5 px-3 md:mb-0"
                                label="From Date"
                                type="date"
                                name="from_date"
                                id="from_date"
                                value={values.from_date}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className={errors.from_date ? 'border border-red-500' : 'border border-blue-600'}
                                error={errors.from_date}
                            />


                            <InputWithLabel
                                DivclassName="w-1/2 md:w-1/5 px-3 md:mb-0"
                                label="To Date"
                                type="date"
                                name="to_date"
                                id="to_date"
                                value={values.to_date}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className={errors.to_date ? 'border border-red-500' : 'border border-blue-600'}
                                error={errors.to_date}
                            />

                            <InputWithLabel
                                DivclassName="w-1/2 md:w-1/5 px-3 md:mb-0"
                                label="VAN"
                                type="text"
                                placeholder="Enter Van"
                                name="van"
                                id="van"
                                value={values.van}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className={errors.van ? 'border border-red-500' : 'border border-blue-600'}
                                error={errors.van}
                            />

                            <SelectWithLabelOption
                                DivclassName="w-1/2 md:w-1/5 px-3 md:mb-0"
                                label="Status"
                                id="status"
                                name="status"
                                value={values.status}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className={errors.status ? 'border border-red-500' : 'border border-blue-600'}
                                error={errors.status}
                            >
                                <option value="">Select Status</option>
                                <option value="New">New</option>
                                <option value="De-Assigned">De-Assigned</option>
                            </SelectWithLabelOption>

                            <InputWithLabel
                                DivclassName="w-1/2 md:w-1/5 px-3 md:mb-0"
                                label="Bank Name"
                                type="text"
                                placeholder="Enter Bank Name"
                                name="BankName"
                                id="BankName"
                                value={values.BankName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className={errors.BankName ? 'border border-red-500' : 'border border-blue-600'}
                                error={errors.BankName}
                            />


                        </DivTag>

                    </DivTag>

                    <DivTag className="flex justify-center bg-white">
                        <Button
                            type="submit"
                            className="text-center text-white bg-blue-700 shadow-lg bg-gradient-to-r from-blue-900 via-teal-400 to-purple-900 font-semibold p-0 rounded-md px-2 py-1 text-lg"
                            onClick={handleSearchClick}>
                            Search
                        </Button>
                    </DivTag>
                    </FormTag>



                    <div className="flex flex-col items-center">
                        <HeadingTag className="text-3xl text-blue-800 font-bold bg-white text-center mt-1">Report</HeadingTag>
                        <div className="flex justify-end w-full"> 
                            <Button className="bg-gradient-to-r from-green-400 to-blue-800 hover:from-pink-500
                      hover:to-yellow-500 text-white border border-white rounded-lg px-1 py-1 mr-4 text-lg animate-bounce" 
                            onClick={openConfigModal}> Insert New Van</Button>
                        </div>
                    </div>

         {/* Print,pdf,excel,SearchFilter */}
          <DivTag className="flex">
                    <DivTag className="mr-auto p-0.5">
                        {/* Button printing */}
                        <Button
                            type="button"
                            className="bg-gradient-to-r from-green-400 to-blue-800 hover:from-pink-500 hover:to-yellow-500 text-white border border-white rounded-lg text-md p-1.5"
                            onClick={handlePrint}
                            text="Print"
                        />

                        {/* Button downloadexcel */}
                        <Button
                            type="button"
                            className="bg-gradient-to-r from-green-400 to-blue-800 hover:from-pink-500 hover:to-yellow-500 text-white border border-white rounded-lg text-md p-1.5"
                            onClick={onDownloadExcel}
                            text="Excel"
                        />

                        {/* Button  downloadPdf  */}
                        <Button
                            type="button"
                            className="bg-gradient-to-r from-green-400 to-blue-800 hover:from-pink-500 hover:to-yellow-500 text-white border border-white rounded-lg text-md p-1.5"
                            onClick={onDownloadPdf}
                            text="PDF"
                        />
                    </DivTag>
                    {/* Input Search Field */}

                    <InputField
                        type="text"
                        value={searchQuery}
                        onChange={handleSearchInputChange}
                        placeholder="Search.........."
                        className="appearance-none block text-gray-700 border rounded mb-1
                leading-tight focus:outline-none focus:bg-white text-center"
                    />
                </DivTag>

                <div className="overflow-x-auto my-1 p-2">
                <table id="table" className="min-w-full divide-y divide-gray-200">
                            <thead className="text-md">
                                <tr className="bg-blue-800 text-center text-white">
                                    <th className="px-4 py-2 border-2">SR No.</th>
                                    <th className="px-4 py-2 border-2">VAN</th>
                                    <th className="px-4 py-2 border-2">Date of Creation</th>
                                    <th className="px-4 py-2 border-2">Status</th>
                                    <th className="px-4 py-2 border-2">Daily Limit</th>
                                    <th className="px-4 py-2 border-2">Bank Name</th>
                                    <th className="px-4 py-2 border-2">Action</th>
                                </tr>
                            </thead>
                            <tbody className="overflow-y-scroll w-80">
                                {filteredItems.map((item, index) => (
                                    <tr key={index}>
                                        <td className='border-2'>{item.SrNo}</td>
                                        <td className='border-2'>
                                            {editIndex === index ? (
                                                <InputField
                                                    className="w-12"
                                                    value={item.Van}
                                                    onChange={(e) => handleInputChange(e, index, 'Van')}
                                                />
                                            ) : (
                                                item.Van
                                            )} </td>
                                        <td className='border-2'>{item.DateOfCreation}</td>
                                        <td className='border-2'>{item.Status}</td>
                                        <td className='border-2'>{item.DailyLimit}</td>
                                        <td className='border-2'>
                                            {editIndex === index ? (
                                                <InputField
                                                    className="w-12"
                                                    value={item.BankName}
                                                    onChange={(e) => handleInputChange(e, index, 'BankName')}
                                                />
                                            ) : (
                                                item.BankName
                                            )} </td>
                                        <td className='border-2'>

                                            {editIndex === index ? (
                                                <Button className="text-white bg-sky-600 px-2 rounded-lg ml-2 animate-pulse" onClick={handleUpdateClick}>Update</Button>
                                            ) : (
                                                <Button className="text-white bg-red-500 pl-2 pr-2 rounded-lg ml-4 me-1" onClick={() => handleEditClick(index)}>Edit</Button>
                                            )}

                                            <Button className="text-white bg-cyan-500 pl-2 pr-2 rounded-lg mt-1" onClick={() => handleDeleteClick(index)}>Delete</Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={paginate} />
        </DivTag>
        {isModalOpenConfig && <PopUpConfig onCloseConfig={closeModalConfig} />}
        </motion.div>
    );
};

export default IdfcVanConfig;
