import React, { useEffect, useId, useMemo, useState } from 'react';
import DivTag from '../../Components/DivComponent/DivTag';

import ReportHeading from '../../Components/ReportHeadings/ReportHeadings';

import { motion } from 'framer-motion';
import InputWithLabel from '../../Components/InputComponent/InputWithLabel';
import Button from '../../Components/ButtonComponent/Button';
import { ToastContainer } from 'react-toastify';
import { AddAmountWalletService, CustomerListService, DeductAmountWalletService } from '../../../services/walletservice';
import PopMenu from '../../../components/Dialog/PopMenu';
import { showErrorToast, showSuccessToast } from '../../../utils/toastUtils';
import Seo from '../../../components/SeoComponent/Seo';
const WalletBalanceManagement = () => {
    const [customerList, setCustomerLists] = useState([]);
    const [addharNumber, setAdharNumber] = useState('');
    const [panNumber, setPanNumer] = useState('');
    const [totalData, setTotalData] = useState(5);
    const [currentPage, setCurrentPage] = useState(1);
    const [paginationData, setPaginationData] = useState()
    const [mobileNumber, setMobileNumber] = useState('');
    const [userId, setUserId] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [addPopUp, setAddPopUp] = useState(false);
    const [deductPopUp, setDeductPopUp] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState('');
    const [amount, setAmount] = useState(0);
    const [balance, setBalance] = useState(0);
    const [filterData, setFilterData] = useState({
        from_date: "",
        to_date: "",
        status: "",
        user_id: "",
        page: "",

    })
    const getCustomerLists = async () => {
        try {


            const response = await CustomerListService({
                aadhar_number: addharNumber,
                pan_number: panNumber,
                mobile: mobileNumber,
                userid: userId.toUpperCase(),
                company_name: companyName,
                total_data: userId !== "" ? 1 : totalData,
                currentPage: currentPage
            });


            setCustomerLists(response.data)
            setPaginationData(response.pagination)

        } catch (error) {
            return
        }
    }
    const handleOpenAddWalletpop = (userId, balance) => {
        setSelectedUserId(userId);
        setBalance(balance)
        setAddPopUp(true);
        return;
    }
    const handleOpenDeductWalletpop = (userId, balance) => {
        setSelectedUserId(userId);
        setBalance(balance)
        setDeductPopUp(true);
        return;
    }
    const AddWalletAmount = async () => {
        try {
            const response = await AddAmountWalletService({
                userId: selectedUserId,
                amount: amount
            });
            if (response.status === "success") {
                setAddPopUp(false);
                setAmount(0)
                showSuccessToast("Amount Add Successfully");
                return;
            }
            showErrorToast(response.message);
            setAddPopUp(false);
        } catch (error) {
            showErrorToast('Failed to add amount');
            setAddPopUp(false);
            return
        }
    }
    const DeductWalletAmount = async () => {
        try {
            const response = await DeductAmountWalletService({
                userId: selectedUserId,
                amount: amount
            });
            if (response.status === "success") {
                setDeductPopUp(false);
                setAmount(0)
                showSuccessToast(response.message);
                return;
            }
            showErrorToast(response.message);
            setAddPopUp(false);
        } catch (error) {
            showErrorToast('Failed to add amount');
            setAddPopUp(false);
            return
        }
    }
    useEffect(() => {
        getCustomerLists();
    }, [addPopUp, deductPopUp, currentPage])


    return (
        <motion.div
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1.2 }}>
            <Seo />
            <DivTag id="" className="mt-8 bg-white py-4">
                <ReportHeading name="Wallet Balance Management" />
                <DivTag className="flex mt-3 justify-center items-center">
                    <InputWithLabel
                        DivclassName="w-full md:w-1/4 px-3 md:mb-0"
                        label="User Id "
                        type="text"
                        name="from_date"
                        id="s"
                        min={12}
                        maxLength={12}
                        placeholder={"USEID01"}
                        value={userId.toUpperCase()}
                        onChange={(e) => setUserId(e.target.value)}
                    />
                    <InputWithLabel
                        DivclassName="w-full md:w-1/4 px-3 md:mb-0"
                        label="Aadhar Number "
                        type="number"
                        name="from_date"
                        id="s"
                        min={12}
                        maxLength={12}
                        placeholder={"Aadhar number"}
                        value={addharNumber}
                        onChange={(e) => setAdharNumber(e.target.value)}
                    />
                    <InputWithLabel
                        DivclassName="w-full md:w-1/4 px-3 md:mb-0"
                        label="Pan Number "
                        type="text"
                        name="from_date"
                        id="s"
                        placeholder={"Pan number"}
                        value={panNumber}
                        onChange={(e) => setPanNumer(e.target.value)}
                    />

                    <InputWithLabel
                        DivclassName="w-full md:w-1/4 px-3 md:mb-0"
                        label="Mobile "
                        type="number"
                        name="from_date"
                        id="s"
                        placeholder={"92822911*"}
                        value={mobileNumber}
                        onChange={(e) => setMobileNumber(e.target.value)}

                    />
                    <InputWithLabel
                        DivclassName="w-full md:w-1/4 px-3 md:mb-0"
                        label="company Name "
                        type="text"
                        name="from_date"
                        id="s"
                        placeholder={"company name "}
                        value={companyName}
                        onChange={(e) => setCompanyName(e.target.value)}
                    />

                </DivTag>
                {/* Add and Deduct Pop up */}
                <PopMenu
                    visible={addPopUp}
                    onClose={() => setAddPopUp(false)}
                    className="w-full max-w-xl h-fit"
                    title="Add Amount"
                >
                    <div className="flex flex-col sm:flex-row gap-2 sm:gap-1 justify-around my-4">
                        <p className="text-lg sm:text-xl text-center">User Id: {selectedUserId}</p>
                        <p className="text-lg sm:text-xl text-center">Available Balance: {balance}</p>
                    </div>

                    <div className="mx-auto w-11/12 sm:w-3/5 my-2">
                        <h4 className="text-black font-bold">
                            Amount <span className="text-red-500 text-xl font-semibold">*</span>
                        </h4>
                        <input
                            type="number"
                            className="border-2 my-2 rounded-md border-sky-400 shadow-md outline-none p-2 text-lg sm:text-xl bg-white text-black w-full"
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)}
                            name="remarks"
                            placeholder="Enter Amount"
                        />
                        <p className="text-red-500 my-2">
                            {amount < 0 ? "Amount must be greater than 0" : ""}
                        </p>
                    </div>

                    <div className="flex justify-center items-center">
                        <button
                            className="px-4 py-2 rounded bg-blue-500 text-white cursor-pointer"
                            disabled={amount <= 0}
                            onClick={AddWalletAmount}
                        >
                            Add
                        </button>
                    </div>
                </PopMenu>

                <PopMenu
                    visible={deductPopUp}
                    onClose={() => setDeductPopUp(false)}
                    className="w-full max-w-xl h-fit"
                    title="Deduct Amount"
                >
                    <div className="flex flex-col sm:flex-row gap-2 sm:gap-1 justify-around my-4">
                        <p className="text-lg sm:text-xl text-center">User Id: {selectedUserId}</p>
                        <p className="text-lg sm:text-xl text-center">Available Balance: {balance}</p>
                    </div>

                    <div className="mx-auto w-11/12 sm:w-3/5 my-2">
                        <h4 className="text-black font-bold">
                            Amount <span className="text-red-500 text-xl font-semibold">*</span>
                        </h4>
                        <input
                            type="number"
                            className="border-2 my-2 rounded-md border-sky-400 shadow-md outline-none p-2 text-lg sm:text-xl bg-white text-black w-full"
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)}
                            name="remarks"
                            placeholder="Enter Amount"
                        />
                        <p className="text-red-500 my-2">
                            {amount < 0
                                ? "Amount must be greater than 0"
                                : amount > balance
                                    ? "Deduct amount must be lower than balance"
                                    : ""}
                        </p>
                    </div>

                    <div className="flex justify-center items-center">
                        <button
                            className="py-1 px-3 rounded bg-blue-500 text-white cursor-pointer"
                            disabled={amount <= 0 || amount > balance}
                            onClick={DeductWalletAmount}
                        >
                            Deduct
                        </button>
                    </div>
                </PopMenu>

                <div className='flex justify-center items-center my-5 gap-5'>
                    <div className='flex flex-col'>
                        <label htmlFor="totalData">Total Data</label>
                        <select name="" id="totalData" className='border-2 border-orange-200 bg-white shadow-sm py-2 rounded-md' value={totalData} onChange={(e) => setTotalData(e.target.value)}>
                            <option value="#" disabled selected>Select Data Per Page</option>
                            <option value="1">1</option>
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="40">40</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="200">200</option>
                        </select>
                    </div>
                    <Button
                        type="button"
                        className="text-center text-white bg-blue-700 shadow-lg bg-gradient-to-r from-blue-900 mt-4
                         via-teal-400 to-purple-900 font-semibold p-0 rounded-md px-2 py-1 text-lg hover:bg-red-300"
                        id="submitApiUserKyc"
                        name="submitApiUserKyc"
                        onClick={getCustomerLists}
                    >Submit
                    </Button>
                </div>
                <DivTag className=" br-6 overflow-x-auto">
                    <table className="table table-hover w-full min-w-full">

                        <thead className="text-md">
                            <tr className="bg-blue-500 text-white text-center">
                                <th className="px-2 py-2">S No.</th>
                                <th className="px-2 py-2">API ID</th>
                                <th className="px-2 py-2">API Name</th>
                                <th className="px-2 py-2">Main Wallet</th>
                                <th className="px-2 py-2">Secondary Wallet</th>

                                <th className="px-2 py-2">Add Fund</th>
                                <th className='px-2 py-2'>Deduct Fund</th>
                            </tr>
                        </thead>
                        <tbody className="overflow-y-scroll w-80">
                            {
                                customerList && customerList.length > 0 ?
                                    customerList.map((item, index) => (
                                        <tr key={index} className='text-center'>
                                            <td>{(currentPage - 1) * (paginationData?.per_page) + index + 1}</td>
                                            <td>{item.user_id}</td>
                                            <td>{item.name}</td>
                                            <th>{item.balance}</th>
                                            <td>{item.hold_balance}</td>

                                            {/* <td>{item.SecondaryWallet}</td> */}
                                            <td><button className='btn bg-blue-500 text-white rounded-md px-1 py-1' onClick={() => handleOpenAddWalletpop(item.user_id, item.balance)}>Add</button></td>
                                            <td><button className='btn bg-red-500 text-white rounded-md px-1 py-1' onClick={() => handleOpenDeductWalletpop(item.user_id, item.balance)}>Deduct</button></td>
                                        </tr>
                                    ))
                                    :
                                    <tr>
                                        <td colSpan="5" className="px-4 py-2 text-center text-black text-xl">Data Not Found</td>
                                    </tr>
                            }
                        </tbody>
                    </table>
                    <div className="w-full flex justify-center gap-10 my-4">
                        <button className="py-1 text-xl rounded-md  px-4 bg-blue-500 text-white border-none cursor-pointer"
                            onClick={() =>
                                setCurrentPage((prev) => Math.max(prev - 1,  1))
                            }
                            disabled={currentPage === 1}

                        >Prev</button>
                        <p className='text-xl text-neutral-500'> {paginationData?.current_page}/ {paginationData?.last_page}</p>
                        <button className="py-1 text-xl rounded-md hover:bg-blue-800 px-4 bg-blue-900 text-white border-none cursor-pointer"
                            onClick={() =>
                                setCurrentPage((prev) => Math.min(prev + 1, paginationData?.last_page || 1))
                            }
                            disabled={currentPage === paginationData?.last_page}
                        >Next</button>
                    </div>
                </DivTag>
                <ToastContainer />
            </DivTag>
        </motion.div>
    );
};
export default WalletBalanceManagement;