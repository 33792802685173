import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import $ from 'jquery';
import ReportHeading from '../../Components/ReportHeadings/ReportHeadings';
import DivTag from '../../Components/DivComponent/DivTag';
import Popup from '../../Components/Popup/Popup';
import Option from '../../Components/OptionComponent/Option';
import FormValidation from '../../Components/FormValidation/FormValidation';
import InputWithLabel from '../../Components/InputComponent/InputWithLabel';
import Button from '../../Components/ButtonComponent/Button';
import SelectWithLabelOption from '../../Components/SelectComponent/SelectWithLabelOption';
import { AddPackageService, retrivePackageService, editPackageService, updatePackageService, retrivePackageServiceByTotal } from '../../../services/PackageList/PackageService';
import { FaInbox } from 'react-icons/fa';
import Seo from '../../../components/SeoComponent/Seo';
import { RetriveServiceReportService } from '../../../services/apiaddservice';
import { linkServicePackage } from '../../../services/apiService';
import { showErrorToast, showSuccessToast } from '../../../utils/toastUtils';
import { ToastContainer } from 'react-toastify';

const CommissionMaster = () => {
  const initialState = {
    service: '',
    status: '',
  };
  const [packageName, setPackageName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [package_value, setpackage_value] = useState('');
  const [service_value_top, setservice_value_top] = useState('');
  const [packageData, setPackageData] = useState([]);
  const [packageID, setPackageID] = useState('');
  const [editingPackageId, setEditingPackageId] = useState(null);
  const [newName, setNewName] = useState('');
  const [serviceData, setServiceData] = useState([]);
  const [selectedService, setSelectedService] = useState();
  const [selectedPackageId, setSelectedPackageId] = useState('');
  const [commissionSlab, setCommisssionSlab] = useState([]);
  const [charges, setCharges] = useState(0);
  const [chargeType, setChargeType] = useState('')
  const validate = (values) => {
    let errors = {};
    if (!values.SelectService || values.SelectService === '') {
      errors.SelectService = 'Service is required';
    }
    if (!values.SelectStatus || values.SelectStatus === '') {
      errors.SelectStatus = 'Status is required';
    }
    return errors;

  };
  const [service, setservice] = useState('');
  const [commission_amt_val, setcommission_amt_val] = useState('');

  const handleCommissionAmtValChange = (event) => {
    setcommission_amt_val(event.target.value);
  }


  const [to_amt_val, setto_amt_val] = useState('');
  const handleToAmt = (event) => {
    setto_amt_val(event.target.value);
  }

  const handleChangeSelected = (event) => {
    setservice(event.target.value);
  }

  const handleChangeServiceTop = (event) => {
    setservice_value_top(event.target.value);
  }
  const handleChangePackage = (event) => {
    setpackage_value(event.target.value);
  }
  const [isOpen, setIsOpen] = useState(false);
  const openPopup = () => {
    setIsOpen(true);
  };

  const closePopup = () => {
    setIsOpen(false);
  };

  const [from_amt_val, setfrom_amt_val] = useState('');
  const handleFromAmt = (event) => {
    setfrom_amt_val(event.target.value);
  }

  const { values, errors, handleChange, handleBlur } = FormValidation(
    initialState,
    validate
  );
  // const editTd = (edit_target) =>{
  //   $('#add_package').val(edit_target);
  //   $('#add_btn').html('Update');
  // }
  let ClickCount = 1;
  const handleClickedAdd = () => {
    AddPackageData();
    if ($('#add_package').val() == '') {
      $('#add_package').addClass('red-border');
    } else {
      $('#add_package').removeClass('red-border');
      if ($('#add_btn').html() == 'Add') {
        document.getElementById('tbody_part_after_add').innerHTML += `<tr><td>${ClickCount}</td><td id='td_${ClickCount}'>${$('#add_package').val()}</td><td class='flex' onclick='editTd("td_${ClickCount}")'><span>Edit</span><span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
      <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
      <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"/>
    </svg></span></td></tr>`;
        ClickCount++;
      } else if ($('#add_btn').html() == 'Update') {
        $('#add_package').val('');
        $('#add_btn').html('Add');
        window.location.reload()
      }
    }
  };


  const [showDropdown, setShowDropdown] = useState(false);
  const [names, setNames] = useState(['John', 'Bobby', 'Bobby', 'Bo', 'Ram', 'Ra', 'ram']);
  const [filteredNames, setFilteredNames] = useState([]);
  const [number_string, setnumber_string] = useState('this');
  const HandleNumberStrin = (event) => {
    console.log(event);
  }



  // //AddBank Data Function
  async function AddPackageData() {
    try {
      setIsLoading(true)
      const response = await AddPackageService(
        {
          name: packageName
        });
        
        if (response.status === 422) {
      
          showErrorToast("Package Already exists");
          return
        }
      if (response.status.code === 200) {
        showSuccessToast(response.status.message);
        await RetrivePackageData();
  
        return;
      }
      

      setPackageName('');
      setIsLoading(false);
      return;
    } catch (error) {
      console.log(error)
    }
  }
  // ENd


  // Retrive Bank Data Function
  async function RetrivePackageData() {
    try {
      const response = await retrivePackageServiceByTotal({
        total:50
      });

      setPackageData(response.data);
    } catch (error) {
      return error;
    }
  }
  async function retriveSrviceReport() {
    try {
      const response = await RetriveServiceReportService();
     
      if (response.status === "success") {
        setServiceData(response.data);
        return
      }
    } catch (error) {
      return
    }
  }
  useEffect(() => {
    RetrivePackageData();
    retriveSrviceReport();
  }, [isLoading])
  // ENd


  // Handle Edit button click
  const handleEdit = (package_id, name) => {
   
    setPackageID(package_id)
    setEditingPackageId(package_id); // Set the package ID to the one being edited
    setNewName(name); // Set the current name to the new name
    
  };

  // Handle Update button click
  const handleUpdate = async (id) => {
    
    try {

      const response = await updatePackageService({ name: newName, package_id: id });

      if (response.status.code===200) {
        await  RetrivePackageData()
       showSuccessToast(response.status.message)
        setEditingPackageId(null); 
        
      } else {
       showErrorToast(response.data.message);
       setEditingPackageId('');
       return;
      }
    } catch (error) {
      console.error('Error during update:', error);
    }
  };

  function addCommissionSlab() {

    const addedItem = {
      "start_amount": from_amt_val,
      "end_amount": to_amt_val,
      "commission_type": chargeType
    };


    function isOverlapping(existingSlabs, newSlab) {
      return existingSlabs.some(slab =>
        (newSlab.start_amount < slab.end_amount && newSlab.end_amount > slab.start_amount)
      );
    }


    function isContiguous(existingSlabs, newSlab) {
      return existingSlabs.some(slab =>
        (newSlab.start_amount <= slab.end_amount && newSlab.end_amount >= slab.start_amount)
      );
    }


    if (isOverlapping(commissionSlab, addedItem)) {
      showErrorToast("The new slab overlaps with an existing slab.");
      return;
    }


    if (!isContiguous(commissionSlab, addedItem) && commissionSlab.length > 0) {
      showErrorToast("The new slab does not fit in the existing range of slabs.");
      return;
    }


    setCommisssionSlab(prev => [...prev, addedItem]);
  }



  function filterSelctedItem() {

    return serviceData.filter((curr) => curr.id == selectedService)
  }
  async function handleSubmitPackageLinking() {
    try {

      if (charges <= 0) {
        return showErrorToast('Charges Amount must be greater than 0')
      }
      if (filterSelctedItem()[0].adjustment_type === "charge") {
        const payload = {
          "service_id": selectedService,
          "package_id": selectedPackageId,
          "amount": charges,
          "charge_type": chargeType
        };
        const response = await linkServicePackage(payload);
        if (response.status.code === 201) {
          setChargeType('')
          setCharges('')
          showSuccessToast(response.status.message);
          return
        }


      }
      if (charges <= 0) {
        return showErrorToast('Charges Amount must be greater than 0')
      }

      if (commissionSlab.length <= 0) {

        return showErrorToast('Add Atlead 1 slab')
      }
      if (commissionSlab.length > 0) {
        const payload = {
          "service_id": selectedService,
          "package_id": selectedPackageId,
          "amount": charges,
          "charge_type": chargeType,
          "commission_slabs": commissionSlab
        }
        const response = await linkServicePackage(payload);
        console.log(response)
        if (response.status.code === 201) {
          setChargeType('')
          setCharges(0)
          setCommisssionSlab([]);
          setfrom_amt_val('')
          setto_amt_val('')

          showSuccessToast(response.status.message);
          return
        }
      }
    } catch (error) {

    }
  }
  return (
    <>
      <Seo />
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1.2 }}>
        {/* <DivTag id="content" className="main-content border-2 border-solid border-blue-800 rounded-xl border-t-0 mt-12 bg-white"> */}
        <DivTag id="" className=" border-2 border-solid border-blue-800 rounded-xl mt-12 bg-white">
          <ReportHeading name="&nbsp;&nbsp; Commission Master&nbsp;&nbsp;" />
          <button onClick={openPopup} id='create_package' type="button" className="text-white bg-gradient-to-r from-cyan-400 via-cyan-500 to-cyan-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 font-medium rounded-lg text-sm px-3 py-2 text-center absolute md:top-[55px] mt-1 right-[5px] mb-2">Create Package</button>


          <Popup isOpen={isOpen} onClose={closePopup} popupMainContentClass='w-450 h-1/2 overflow-y-scroll'>
            {/* <ReportHeading name="&nbsp;&nbsp;Create Package&nbsp;&nbsp;"/> */}
            <p className='bg-blue-600 rounded-md w-32 text-white px-1 mb-6'>Create Package</p>
            <div className="flex gap-2 justify-center">
              <InputWithLabel
                label='Package Name'
                placeholder='Enter Package Name'
                value={packageName}
                //  onChange={change_color_default}
                onChange={(e) => setPackageName(e.target.value)}
                name='add_package'
                id=''
                type='text'
              />
              <Button onClick={AddPackageData}
                id='add_btn' type="submit"
                className="text-center  h-fit text-white bg-blue-700 shadow-lg bg-gradient-to-r mt-6 from-blue-900 via-teal-400 to-purple-900 font-semibold rounded-md px-1 text-xl hover:bg-red-300">Add</Button>
            </div>
            <div className="mt-2 ">
              <table className="table ">
                <thead>
                  <tr>
                    <th>SN</th>
                    <th>Package Name</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    packageData.length > 0 ?
                      packageData.map((value, index) => (
                        <tr key={index}>
                          <td className="border-2 px-2 md:px-4 py-2 text-center">{index + 1}</td>
                          <td className="border-2 px-2 md:px-4 py-2 text-center">
                            {editingPackageId === value.id ? (
                              <input
                                type="text"
                                value={newName}
                                onChange={(e) => setNewName(e.target.value)}
                                className="border rounded px-2 py-1"
                              />
                            ) : (
                              value.name 
                            )}
                           
                          </td>
                          <td className="border-2 px-2 md:px-4 py-2 text-center">
                            {editingPackageId === value.id ? (
                              <button
                                className="bg-green-600 text-white rounded-md text-xs px-1"
                                onClick={() => handleUpdate(value.id)}
                              >
                                Update
                              </button>
                            ) : (
                              <button
                                className="bg-blue-600 rounded-md text-white text-xs px-1 me-1"
                                onClick={() => handleEdit(value.id, value.name)}
                              >
                                Edit
                              </button>
                            )}
                          </td>
                        </tr>
                      ))
                      :
                      <div className='flex items-center gap-4 text-xl text-neutral-600 justify-center'>
                        <FaInbox />
                        <p className='flex justify-center items-center'>Data not found</p>
                      </div>
                  }
                </tbody>


                {/* <tbody id='tbody_part_after_add'>
                          
                        </tbody> */}
              </table>
            </div>
          </Popup>


          <form method="post" autoComplete="off">
            <DivTag className="flex flex-wrap pt-3 mt-2 justify-center">
              <SelectWithLabelOption DivclassName="w-full md:w-1/5 px-3 md:mb-0" label="Select Package" id="select_package" name="select_package" value={selectedPackageId} onChange={(e) => setSelectedPackageId(e.target.value)} onBlur={handleBlur} className={errors.service ? 'border border-red-500' : 'border border-blue-600'} error={errors.service}>
                <Option value="" selected disabled>Select Package</Option>
                {packageData.length > 0 ?
                  packageData.map((value, index) => (
                    <Option key={index} value={value.id}>{value.name}</Option>

                  )) : ''
                }
              </SelectWithLabelOption>

              <SelectWithLabelOption DivclassName="w-full md:w-1/5 px-3 md:mb-0" label="Select Service" id="service" name="service" value={selectedService} onChange={(e) => setSelectedService(e.target.value)} onBlur={handleBlur} className={errors.service ? 'border border-red-500' : 'border border-blue-600'} error={errors.service}>
                <Option value="" selected disabled>Select Service</Option>
                {
                  serviceData.length > 0 ?
                    serviceData.map((item, index) => (
                      <Option key={index} value={item.id}>{item.service_name}</Option>
                    ))
                    : ""
                }
              </SelectWithLabelOption>
              {
                filterSelctedItem()[0]?.adjustment_type !== "charge"
                  ?

                  <InputWithLabel DivclassName="w-full md:w-1/5 px-2 md:mb-0 text-left mb-0 text-black"
                    DivTagClassName="absolute z-60 ml-3 mt-16 w-40 bg-white border rounded shadow-md" label="Charges Amount" value={charges} placeholder='Enter Charges Amount' type="number" name="commission_amt_" id="commission_amt_" onChange={(e) => setCharges(e.target.value)} className="border" required={true} />
                  :
                  ""
              }
              <Button type="button"
                onClick={handleSubmitPackageLinking}
                className="text-center h-fit  text-white bg-blue-700 shadow-lg bg-gradient-to-r mt-3 from-blue-900 via-teal-400 to-purple-900 font-semibold rounded-md px-2 py-1 text-lg hover:bg-red-300">Submit</Button>
            </DivTag>

          </form>
          <div className='my-3 d-none' id='bottom_content'>

            <form method='post' action="">
              {
                filterSelctedItem()[0]?.adjustment_type === "charge"
                  ?
                  <>
                    <DivTag className="flex flex-wrap pt-3 mt-2 justify-center">




                      <InputWithLabel DivclassName="w-full md:w-1/5 px-2 md:mb-0 text-left mb-0 text-white"
                        DivTagClassName="absolute z-60 ml-3 mt-16 w-40 bg-white border rounded shadow-md" label="Charges Amount" value={charges} placeholder='Enter Charges Amount' type="number" name="commission_amt_" id="commission_amt_" onChange={(e) => setCharges(e.target.value)} className="border" />

                      <SelectWithLabelOption DivclassName="w-full md:w-1/5 px-3 md:mb-0" label="Select Type" id="service_bottom" name="service_bottom" value={chargeType} onChange={(e) => setChargeType(e.target.value)} className={""}>
                        <Option selected value="">Select Type</Option>
                        <Option value="Flat">Flat</Option>
                        <Option value="Percent">Percentage</Option>
                      </SelectWithLabelOption>

                    </DivTag>

                  </>
                  :
                  <>
                    <DivTag className="flex flex-wrap pt-3 mt-2 justify-center">
                      <InputWithLabel DivclassName="w-full md:w-1/5 px-2 md:mb-0 text-left mb-0 text-white"
                        DivTagClassName="absolute z-60 ml-3 mt-16 w-40 bg-white border rounded shadow-md" label="From Amount" placeholder='Enter From Amount' value={from_amt_val} type="text" name="from_amt_" id="from_amt_" onChange={handleFromAmt} className="border" />

                      <InputWithLabel DivclassName="w-full md:w-1/5 px-2 md:mb-0 text-left mb-0 text-white"
                        DivTagClassName="absolute z-60 ml-3 mt-16 w-40 bg-white border rounded shadow-md" label="To Amount" placeholder='Enter To Amount' type="text" value={to_amt_val} name="to_amt_" id="to_amt_" onChange={handleToAmt} className="border" />



                      <SelectWithLabelOption DivclassName="w-full md:w-1/5 px-3 md:mb-0" label="Select Type" id="service_bottom" name="service_bottom" value={chargeType} onChange={(e) => setChargeType(e.target.value)} onBlur={handleBlur} className={errors.service ? 'border border-red-500' : 'border border-blue-600'} error={errors.service}>
                        <Option selected value="">Select Type</Option>
                        <Option value="Flat">Flat</Option>
                        <Option value="Percent">Percentage</Option>
                      </SelectWithLabelOption>
                      <Button type="button" id='add_bottom_data' className="text-center h-fit  text-white bg-blue-700 shadow-lg bg-gradient-to-r mt-3 from-blue-900 via-teal-400 to-purple-900 font-semibold rounded-md px-2 py-1 text-lg hover:bg-red-300" onClick={addCommissionSlab}>Add</Button>
                    </DivTag>
                    {
                      commissionSlab.length > 0
                        ?
                        <div className='w-full flex justify-center my-3 text-center'>
                          <table>
                            <thead className='bg-blue-700 text-white'>
                              <th>S.N</th>
                              <th>From Amount</th>
                              <th>To Amount</th>
                              <th>Commission_Tpe</th>

                            </thead>
                            <tbody>
                              {
                                commissionSlab.map((item, index) => (
                                  <tr>
                                    <td className='text-center'>{index}</td>
                                    <td className='text-center'>{item.start_amount}</td>
                                    <td className='text-center'>{item.end_amount}</td>
                                    <td className='text-center'>{item.commission_type}</td>
                                  </tr>
                                ))
                              }
                            </tbody>
                          </table>
                        </div>
                        :
                        ''
                    }
                  </>
              }
              <p id="error_bottom_side" className=' text-red-500 text-center d-none'>Please Input all Fields</p>
            </form>
            <div className="flex justify-center mb-1overflow-x-auto">
              <table className="min-w-full table-auto border-collapse">
                <thead>
                  <tr className='bg-blue-800 text-center text-white'>
                    <th className='border-2 p-2'>SN</th>
                    <th className='border-2'>From Amount</th>
                    <th className='border-2'>To Amount</th>
                    <th className='border-2'>Commission</th>
                    <th className='border-2'>Type</th>
                    <th className='border-2'>Service</th>
                    <th className='border-2'>Action</th>
                    <th className='border-2'>Add Date</th>
                  </tr>
                </thead>
                <tbody id='bottom_table_main_data'>
                </tbody>
              </table>
            </div>
          </div>
          <ToastContainer />
        </DivTag>
      </motion.div>
    </>
  );
};

export default CommissionMaster;